import React from 'react'

const Layout = () => {
  return (
    <div className='flex flex-col justify-between'>
        <h1 className='text-3xl text-white'>RPC<span className='text-initialColor'>-Dapps</span></h1>
        <div>

        </div>
    </div>
  )
}

export default Layout