const Wallets =[
    {
        name:'MetaMask',
        icon:'./Assets/Wallets/MetaMask.png'
    },{
        name:'Trust',
        icon:'./Assets/Wallets/Trust.svg'
    },{
        name:'WalletConnect',
        icon:'./Assets/Wallets/WalletConnect.png'
    },{
        name:'Binance Smart Chain',
        icon:'./Assets/Wallets/BNS.jpg'
    },{
        name:'Atomic',
        icon:'./Assets/Wallets/atomic.jpg'
    },{
        name:'Crypto.com | Defi Wallet',
        icon:'./Assets/Wallets/crypto.jpg'
    },{
        name:'Pancake Swap',
        icon:'./Assets/Wallets/pancake.png'
    },{
        name:'Solona',
        icon:'./Assets/Wallets/Solana.png'
    },{
        name:'Coinbase',
        icon:'./Assets/Wallets/Coinbase.png'
    },{
        name:'Keplr',
        icon:'./Assets/Wallets/keplr.svg'
    },{
        name:'Maiar',
        icon:'./Assets/Wallets/maiar-dex.png'
    },{
        name:'Nomic',
        icon:'./Assets/Wallets/nomic.png'
    },{
        name:'Zoid Pay',
        icon:'./Assets/Wallets/zoidpay.png'
    },{
        name:'CoinCheck',
        icon:'./Assets/Wallets/CoinCHeck.svg'
    },{
        name:'Ellipal',
        icon:'./Assets/Wallets/ellipal.png'
    },{
        name:'Rainbow',
        icon:'./Assets/Wallets/rainbow.jpg'
    },{
        name:'Argent',
        icon:'./Assets/Wallets/arge.jpg'
    },{
        name:'Gnosis Safe multisig',
        icon:'./Assets/Wallets/gnosis.jpg'
    },{
        name:'Pillar',
        icon:'./Assets/Wallets/pillar.jpg'
    },{
        name:'imToken',
        icon:'./Assets/Wallets/imtoken.jpg'
    },{
        name:'ONTO',
        icon:'./Assets/Wallets/ONTO.jpg'
    },{
        name:'Tokenpocket',
        icon:'./Assets/Wallets/tokenpocket.jpg'
    },{
        name:'MathWallet',
        icon:'./Assets/Wallets/math.jpg'
    },{
        name:'BitPay',
        icon:'./Assets/Wallets/bitpay.jpg'
    },{
        name:'Ledger Live',
        icon:'./Assets/Wallets/'
    },{
        name:'Walleth',
        icon:'./Assets/Wallets/walleth.jpg'
    },{
        name:'Phantom',
        icon:'./Assets/Wallets/phantom.png'
    },{
        name:'Authereum',
        icon:'./Assets/Wallets/authereum.jpg'
    },{
        name:'Dharma',
        icon:'./Assets/Wallets/dharma.jpg'
    },{
        name:'1inch Wallet',
        icon:'./Assets/Wallets/1inch.jpg'
    },{
        name:'Huobi Wallet',
        icon:'./Assets/Wallets/Huobi.jpg'
    },{
        name:'Eidoo',
        icon:'./Assets/Wallets/Eidoo.jpg'
    },{
        name:'Etherscan',
        icon:'./Assets/Wallets/etherscan.jpg'
    },{
        name:'Uniswap',
        icon:'./Assets/Wallets/uniswap.jpg'
    },{
        name:'binance Dex',
        icon:'./Assets/Wallets/BNS.jpg'
    },{
        name:'OpenSea',
        icon:'./Assets/Wallets/opensea.jpg'
    },{
        name:'Compound',
        icon:'./Assets/Wallets/compound.jpg'
    },{
        name:'Zapper',
        icon:'./Assets/Wallets/zapper.jpg'
    },{
        name:'Aave',
        icon:'./Assets/Wallets/aave.jpg'
    },{
        name:'Zerion',
        icon:'./Assets/Wallets/BNS.jpg'
    },{
        name:'Local Cryptos',
        icon:'./Assets/Wallets/local cryptos.jpg'
    },{
        name:'Debank',
        icon:'./Assets/Wallets/debank.jpg'
    },{
        name:'Unstoppable Domains',
        icon:'./Assets/Wallets/unstoppable.jpg'
    },{
        name:'Rarible',
        icon:'./Assets/Wallets/rarible.jpg'
    },{
        name:'1inch Exchange',
        icon:'./Assets/Wallets/1inch.jpg'
    },{
        name:'Yearn',
        icon:'./Assets/Wallets/yearn.jpg'
    },{
        name:'Nash',
        icon:'./Assets/Wallets/nash.jpg'
    },{
        name:'Curve',
        icon:'./Assets/Wallets/curve.jpg'
    },{
        name:'Adex Network',
        icon:'./Assets/Wallets/adex.jpg'
    },{
        name:'dYdX',
        icon:'./Assets/Wallets/dydx.jpg'
    },{
        name:'MyCrypto',
        icon:'./Assets/Wallets/mycrypto.jpg'
    },{
        name:'MYKEY',
        icon:'./Assets/Wallets/mykey.jpg'
    },{
        name:'Loopring',
        icon:'./Assets/Wallets/loopring.jpg'
    },{
        name:'Trust Vault',
        icon:'./Assets/Wallets/trustvault.jpg'
    },{
        name:'Coin98',
        icon:'./Assets/Wallets/coin98.jpg'
    },{
        name:'CoolWallet S',
        icon:'./Assets/Wallets/Coolwallet S.jpg'
    },{
        name:'Alice',
        icon:'./Assets/Wallets/alice.jpg'
    },{
        name:'Alpha Wallet',
        icon:'./Assets/Wallets/alpha.jpg'
    },{
        name:"D'CENT Wallet",
        icon:"./Assets/Wallets/d'cent.jpg"
    },{
        name:'Zelcore',
        icon:'./Assets/Wallets/zelcore.jpg'
    },{
        name:'Coinomi',
        icon:'./Assets/Wallets/coinomi.jpg'
    },{
        name:'Grid Plus',
        icon:'./Assets/Wallets/gridplud.jpg'
    },{
        name:'CYBAVO Wallet',
        icon:'./Assets/Wallets/cybavo.jpg'
    },{
        name:'Tokenary',
        icon:'./Assets/Wallets/tokenary.jpg'
    },{
        name:'Torus',
        icon:'./Assets/Wallets/torus.jpg'
    },{
        name:'Spatium',
        icon:'./Assets/Wallets/spatium.jpg'
    },{
        name:'Safe Pal',
        icon:'./Assets/Wallets/safepal.jpg'
    },{
        name:'Infinito',
        icon:'./Assets/Wallets/infinito.jpg'
    },{
        name:'Other wallets',
        icon:'./Assets/Wallets/other wallets.jpg'
    },
]

export default Wallets