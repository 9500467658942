const Services = [
    {
        title:'Rectification',
        detail:'Rectification support warranties completely decentralized',
        icon:''
    },{
        title:'Validation',
        detail:'Validation completely decentralized supports wallet',
        icon:''
    },{
        title:'Recovery',
        detail:'Recovery wallet TATs Dapps with Blockchain and DeFi',
        icon:''
    },{
        title:'Swap Token',
        detail:'Swap tokens of any type to tokens of any type',
        icon:''
    },{
        title:'Harvest my stakings',
        detail:'When you choose to redeem, we’ll return your assets to your Spot Wallet the following day.',
        icon:''
    },{
        title:'Claim option',
        detail:'Claim all initial due tokens as promised on the promotional terms',
        icon:''
    },{
        title:'Transfer Option',
        detail:'Transfer tokens or coins to friends anywhere around the world.',
        icon:''
    },{
        title:'Withdraw',
        detail:'A crypto withdrawal is a transaction that allows you to move your cryptocurrency balance off the Revolut platform to an external crypto wallet that you control such as a Ledger or a Trezor.',
        icon:''
    },{
        title:'Staking Pool',
        detail:'A staking pool allows multiple stakeholders (or bagholders) to combine their computational resources as a way to increase their chances of being rewarded.',
        icon:''
    },{
        title:'Migration',
        detail:'Migrate tokens to a new contract',
        icon:''
    },{
        title:'Add Tokens',
        detail:'Crypto tokens are a type of cryptocurrency that represents an asset or specific use and reside on their own blockchain.',
        icon:''
    },{
        title:'Update my portfolio',
        detail:'Generate Real-Time Profit and Loss Reports, Your Coin Values, Reports for Taxes and More.',
        icon:''
    },{
        title:'Purchase Token',
        detail:'Also, buy bitcoin, ether, and many common cryptocurrencies.',
        icon:''
    },{
        title:'My NFTs',
        detail:'view your collection of digital works',
        icon:''
    },{
        title:'Staking Review',
        detail:'Interaction between mobile apps and mobile browsers are supported via mobile deep linking Having complete control of your staking',
        icon:''
    },{
        title:'Missing/irregular balance',
        detail:'Recover lost/missing funds',
        icon:''
    },{
        title:'WhiteList',
        detail:'Get an Early Spot for Minting.',
        icon:''
    },{
        title:'Bridge',
        detail:'enables applications built on different blockchains to communicate with and interact with each other. On launch, Ethereum, BSC, Avalanche, and Polygon – four of the leading blockchains and protocols – are supported, with capabilities to add new blockchains built-in to the Alliance Bridge protocol.',
        icon:''
    },
]

export default Services